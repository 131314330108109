var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('h2',{staticClass:"mb-4"},[_vm._v("ADI and Spec Grading Quiz")]),_c('p',{staticClass:"mb-n3"},[_c('b',[_vm._v("Review the Course Syllabus.")]),_vm._v(" Specifications grading is used in this course because: ")]),_c('v-radio-group',{staticClass:"ml-6 mb-1",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}},_vm._l((_vm.options1),function(option){return _c('v-radio',{key:'pt-1-' + option.value,staticClass:"my-1",attrs:{"value":option.value,"label":option.text}})}),1),_c('p',[_vm._v(" This type of grading requires the passing of all grading bundles at a certain level to earn a desired grade. Grading bundles are associated with the "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3 my-n3",staticStyle:{"width":"180px"},attrs:{"items":_vm.options2,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}}),_vm._v(" the course and calculated "),_c('v-select',{staticClass:"d-sm-inline-block ml-3 mr-3 my-n3",staticStyle:{"width":"100px"},attrs:{"items":_vm.options3,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" all or many assignments. ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }