<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">ADI and Spec Grading Quiz</h2>

      <p class="mb-n3">
        <b>Review the Course Syllabus.</b> Specifications grading is used in this course because:
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>

      <p>
        This type of grading requires the passing of all grading bundles at a certain level to earn
        a desired grade. Grading bundles are associated with the

        <v-select
          v-model="inputs.input2"
          :items="options2"
          class="d-sm-inline-block ml-3 mr-3 my-n3"
          style="width: 180px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>

        the course and calculated

        <v-select
          v-model="inputs.input3"
          :items="options3"
          class="d-sm-inline-block ml-3 mr-3 my-n3"
          style="width: 100px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
        all or many assignments.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {
          text: 'Students learn and retain course content better because they can revise and resubmit assignments',
          value: 'a',
        },
        {
          text: "Increased collaboration among students because they aren't competing against a curve ",
          value: 'b',
        },
        {text: 'Both of these', value: 'c'},
      ],
      options2: [
        {text: 'individual assignments in ', value: 'individualAssignments'},
        {text: 'first part', value: 'firstPart'},
        {text: 'learning outcomes of ', value: 'learningOutcomes'},
      ],
      options3: [
        {text: 'across', value: 'across'},
        {text: 'within', value: 'within'},
      ],
    };
  },
};
</script>
<style scoped></style>
